import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WorkCard from "../components/WorkCard"
import Helmet from "react-helmet"
import resetTransitionHomePage from "../modules/resetTransitionOnHomePage"
import ogImage from "../../content/assets/velvetzoo-og-image.jpg"
import ogImageSq from "../../content/assets/velvetzoo-og-image-1200.jpg"
import linkAfterAnimations from "../modules/linkAfterAnimations"

const WorkIndex = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const works = data.allMarkdownRemark.edges
  const mainTitle = "UX/UI Design and code to create digital experiences"
  const image = ogImage
  const imageSq = ogImageSq
  let workCounter = 0

  if (typeof window !== "undefined") {
    setTimeout(resetTransitionHomePage, 1100)
  }

  return (
    <Layout title={siteTitle}>
      <SEO
        title="UX/UI Design and Development in Barcelona"
        keywords={[
          `Graphic Design`,
          `UI`,
          `User Interfaces`,
          `UX`,
          `User Experience`,
          `Brand Design`,
          `development`,
        ]}
        image={image}
        imageSq={imageSq}
      />
      <Helmet>
        <body className="main" id="body" />
      </Helmet>

      {data.site.siteMetadata.description && (
        <header id="pageHead" className="page-head">
          <h1 id="pageTitle" className="page-head-title big-font">
            {mainTitle}
          </h1>
        </header>
      )}

      <section
        id="works"
        className="section-works"
        style={{ backgroundColor: "#000" }}
      >
        <div className="app-container">
          <div className="p-bottom-md">
            <h2 className="big-font">Featured works</h2>
          </div>

          <div className="post-feed">
            {works.map(({ node }) => {
              if (
                node.frontmatter.type &&
                node.frontmatter.type === "featured"
              ) {
                workCounter++
                return (
                  <WorkCard
                    key={node.fields.slug}
                    count={workCounter}
                    node={node}
                    postClass={`post`}
                  />
                )
              } else {
                return null
              }
            })}
          </div>

          <Link
            className="c-white f-21"
            to={`/works`}
            onClick={e => {
              e.preventDefault()
              linkAfterAnimations("/works", "1100")
            }}
          >
            Explore more Works{" "}
            <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
              <path
                fill="#ffffff"
                d="M28.05 35.9 25.9 33.8 34.3 25.4H8V22.4H34.3L25.85 13.95L28 11.85L40.05 23.9Z"
              />
            </svg>
          </Link>
        </div>
      </section>

      <section className="section-description p-top-md">
        <div className="app-container">
          <h2 className="big-font">
            Responsive web apps, development and creative design to cover a
            large range of visual communication
          </h2>
        </div>
      </section>

      <section className="section-contact p-bottom-sm">
        <div className="app-container">
          <h3 className="big-font c-base">Hi There</h3>
          <p>
            If you want to contact, collaborate or you want to talk about a
            project,
            <br />
            send an email to <a href="info@velvetzoo.es">info@velvetzoo.es</a>
          </p>
        </div>
      </section>
    </Layout>
  )
}
const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___id], order: ASC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            type
            longDesc
            clientDesc
            workDesc
            tags
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <WorkIndex location={props.location} props data={data} {...props} />
    )}
  />
)
