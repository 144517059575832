import React from "react"
import { Link } from "gatsby"
let everyNumberofDivs = "0"

export default props => (
  <div className="work-card">
    <div
      className={`post-card ${props.count % everyNumberofDivs === 0 &&
        `post-card-large`} ${props.postClass} ${
        props.node.frontmatter.thumbnail ? `with-image` : `no-image`
      }`}
    >
      <Link
        to={props.node.fields.slug}
        className="post-card-link"
        onClick={e => {
          e.preventDefault()
          let link2Work = props.node.fields.slug
          let workLocationHref = "location.href =  " + link2Work + ""
          let siteMain = document.getElementById("site-main")
          setTimeout(workLocationHref, 1000)
          document.getElementById("transition").classList.add("isMoving")
          //Animate main Div up
          siteMain.classList.add("transition-slide_up")
        }}
      >
        <div className="post-card-content">
          {/*<h2 className="post-card-title">
            {props.node.frontmatter.title || props.node.fields.slug}
            <br />
            <span>{props.node.frontmatter.description}</span>
          </h2>*/}
        </div>
      </Link>
      <div
        className="post-card-image-bg"
        style={
          props.node.frontmatter.thumbnail && {
            backgroundImage: `url(${props.node.frontmatter.thumbnail.childImageSharp.fluid.src})`,
          }
        }
      >
        {" "}
      </div>
    </div>
    <h2 className="post-card-title">
      <span>{props.node.frontmatter.title || props.node.fields.slug}</span>
      <br />
      {props.node.frontmatter.description}
    </h2>
    <br />
  </div>
)
